<template>
  <div>
    <v-card flat :loading="loadingStock">
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-menu offset-y left :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon
              ><v-icon>view_week</v-icon></v-btn
            >
          </template>
          <v-card color="greyRaised" style="overflow: hidden">
            <v-list dense>
              <v-list-item
                v-for="(item, index) in allHeaders.filter((x) => x.selectable)"
                :key="index"
              >
                <v-list-item-action class="pr-0">
                  <v-checkbox
                    v-model="item.show"
                    @change="updateSelectedHeaders()"
                    hide-details
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>
      <v-card-text class="mt-0 pt-0">
        <table
          :key="productKey"
          style="width: 100%; max-height: 30vh; overflow-y: auto"
        >
          <thead style="position: sticky">
            <th
              class="text-center"
              style="cursor: grab"
              v-for="(header, index) in productSummaryHeaders"
              :key="index"
            >
              <v-chip
                v-if="
                  header.value == 'palletQuantity' ||
                  header.value == 'cartonQuantity'
                "
              >
                {{ sum(header.value) }}
              </v-chip>
            </th>
            <draggable
              v-model="productSummaryHeaders"
              tag="tr"
              :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
              :animation="200"
              ghost-class="ghost-card"
              @start="columnDrag = true"
              @end="columnDrag = false"
              @change="updateHeaderSelection()"
            >
              <th
                class="text-center"
                v-for="(header, index) in productSummaryHeaders"
                :key="index"
              >
                <b>{{ header.text }}</b>
                <v-badge
                  v-if="header.selectable"
                  :content="
                    filters[header.value] ? filters[header.value].length : false
                  "
                  :value="
                    filters[header.value] && filters[header.value].length > 0
                  "
                  color="primary"
                  offset-y="10"
                >
                  <!-- <el-button @click="filterDialog = !filterDialog" el-tooltip="Advanced Filter">
                                        <v-icon small>filter_alt</v-icon>
                                    </el-button> -->
                  <v-menu offset-y left :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small class="ml-1" icon
                        ><v-icon small>filter_alt</v-icon></v-btn
                      >
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in headerOptions(header.value)"
                        :key="index"
                      >
                        <v-list-item-action class="pr-0">
                          <v-checkbox
                            v-model="filters[header.value]"
                            :value="item.value"
                            hide-details
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-badge>
              </th>
            </draggable>
            <tr></tr>
          </thead>
          <tbody style="height: 50vh; overflow-y: auto">
            <tr v-for="(item, i) in productBreakdown" :key="i">
              <template v-for="(header, index) in displayHeaders">
                <td
                  :key="index"
                  v-if="
                    header.value != 'cartonPrice' &&
                    header.value != 'kgPrice' &&
                    item[header.value] != null
                  "
                  :style="{ border: '0.4px solid rgb(95, 95, 95)' }"
                  :rowspan="
                    item[header.value + '_rowspan']
                      ? item[header.value + '_rowspan']
                      : 1
                  "
                  class="text-center"
                >
                  <v-btn
                    small
                    class="mr-1"
                    text
                    @click="viewItems(item, header.value)"
                  >
                    {{ item[header.value] }}
                  </v-btn>
                </td>
                <td
                  v-else-if="item[header.value] != null"
                  :key="index"
                  class="text-center"
                >
                  <v-icon color="amber">lock</v-icon>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import draggable from "vuedraggable";
// import StockBreakdownTable from '../../SystemComponents/StockBreakdownTable.vue'
export default {
  components: {
    draggable,
    // StockBreakdownTable
  },
  data: () => ({
    columnDrag: false,
    filters: {
      phc: [],
      puc: [],
      locationCode: [],
      commodityCode: [],
      varietyCode: [],
      mark: [],
      inventoryCode: [],
      packCode: [],
      grade: [],
      countSize: [],
      status: [],
    },
    loadingProducts: false,
    loadingStock: false,
    productKey: 4000,
    groupBy: [
      "puc",
      "phc",
      "locationCode",
      "commodityCode",
      "varietyCode",
      "mark",
      "inventoryCode",
      "packCode",
      "grade",
      "countSize",
      "status",
    ],
    productSummaryHeaders: [],
    allHeaders: [
      {
        text: "PUC",
        value: "puc",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "PHC",
        value: "phc",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Location",
        value: "locationCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Product",
        value: "commodityCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Variety",
        value: "varietyCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Brand/Mark",
        value: "mark",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Inventory Code",
        value: "inventoryCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Grade/Class",
        value: "grade",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Pack Code",
        value: "packCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Count/Size",
        value: "countSize",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "No. Pallets",
        value: "palletQuantity",
        align: "center",
        isQuantity: true,
        required: true,
      },
      {
        text: "No. Cartons",
        value: "cartonQuantity",
        align: "center",
        isQuantity: true,
        required: true,
      },
      // {
      //     text: "Percentage",
      //     value: "percentage",
      //     align: "center",
      // }
    ],
    products: [],
    stock: [],
  }),
  computed: {
    displayHeaders() {
      return this.productSummaryHeaders.filter((x) => x.show || x.required);
    },
    filteredStock() {
      let result = JSON.parse(JSON.stringify(this.stock));
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      return result;
    },
    formatHeaders() {
      let result = [];
      for (let i = 0; i < this.groupBy.length; i++) {
        let getObject = this.getHeaderDetail(this.groupBy[i]);
      }
      return result;
    },
    productBreakdown() {
      return this.groupData();
    },
  },
  async created() {
    this.productSummaryHeaders = [...this.allHeaders];
    await this.getProducts();
    this.getActiveStock();
  },
  methods: {
    constructGroups() {
      let groupBy = [...this.groupBy];
      let result = [];
      let initialCount = groupBy.length;
      while (initialCount > 0) {
        let group = groupBy.slice(0, initialCount);
        result.push(group);
        initialCount--;
      }
      return result;
    },
    formatHeaderName(value, field, data) {
      switch (field) {
        case "productId":
          return data.product.name;
        case "varietyProductId":
          return data.varietyProduct.name;
        default:
          return value;
      }
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.stock
            .filter((x) => x[value])
            .map((x) => ({
              name: this.formatHeaderName(x[value], value, x),
              value: x[value],
            }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      return result;
    },
    async getActiveStock() {
      this.loadingStock = true;
      this.stock = await this.$API.getActiveStockOverview();
      this.loadingStock = false;
      this.productKey++;
    },
    async getProducts() {
      this.loadingProducts = true;
      let data = await this.$API.getProducts();
      this.products = data.data;
      this.loadingProducts = false;
    },
    getHeaderDetail(group) {
      switch (group) {
        case "phc":
          return {
            text: "Packhouse",
            value: "phc",
            align: "center",
            initialValue: group,
          };
        case "productId":
          return {
            text: "Product",
            value: "product",
            align: "center",
            initialValue: group,
          };
        case "varietyProductId":
          return {
            text: "Variety",
            value: "varietyProduct",
            align: "center",
            initialValue: group,
          };
        case "packCode":
          return {
            text: "Packcode",
            value: "packCode",
            align: "center",
            initialValue: group,
          };
        case "grade":
          return {
            text: "Grade",
            value: "grade",
            align: "center",
            initialValue: group,
          };
        case "countSize":
          return {
            text: "Count",
            value: "countSize",
            align: "center",
            initialValue: group,
          };
        case "status":
          return {
            text: "status",
            value: "Status",
            align: "center",
            initialValue: group,
          };
        case "cartonQuantity":
          return {
            text: "No. Cartons",
            value: "cartonQuantity",
            align: "center",
            initialValue: group,
          };
        default:
          return {
            text: group,
            value: group,
            align: "center",
            initialValue: group,
          };
      }
    },
    formatObjects(result) {
      if (this.groupBy.includes("productId")) {
        result = result.map((item) => {
          if (item.productId) {
            let findProduct = this.products.find(
              (product) => product.id == item.productId
            );
            item.productId = findProduct ? findProduct.name : null;
          }
          return item;
        });
      }
      if (this.groupBy.includes("varietyProductId")) {
        result = result.map((item) => {
          if (item.varietyProductId) {
            let findProduct = this.products.find(
              (product) => product.id == item.varietyProductId
            );
            item.varietyProductId = findProduct ? findProduct.name : null;
          }
          return item;
        });
      }
      return result;
    },
    groupData() {
      let pallets = JSON.parse(JSON.stringify(this.filteredStock));
      let result = [];
      let groups = this.constructGroups();
      groups.reverse();
      let uniqueGroups = [];
      let total = pallets.reduce((a, b) => a + parseInt(b.cartonQuantity), 0);
      let maxLevel = 0;
      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < pallets.length; j++) {
          if (!pallets[j].groups) {
            pallets[j].groups = [];
          }
          let values = groups[i].map((x) => pallets[j][x]);
          let hash = values.join(":");
          pallets[j].groups.push(hash);
          if (!uniqueGroups.includes(hash)) {
            uniqueGroups.push(hash);
          }
        }
        let productResult = [];
        for (let j = 0; j < uniqueGroups.length; j++) {
          let groupPallets = pallets.filter(
            (x) => x.groups[i] && x.groups[i] == uniqueGroups[j]
          );
          if (groupPallets.length > 0) {
            let detail = groupPallets[0];
            let parent = null;
            if (i > 0) {
              parent = detail.groups[i - 1];
            }
            maxLevel = i + 1;
            let obj = {
              level: i + 1,
              hash: uniqueGroups[j],
              parent: parent,
              cartonQuantity: groupPallets.reduce(
                (a, b) => a + parseInt(b.cartonQuantity),
                0
              ),
              palletQuantity:
                Math.round(
                  groupPallets.reduce(
                    (a, b) => a + parseFloat(b.palletQuantity),
                    0
                  ) * 100
                ) / 100,
              percentage:
                Math.round(
                  (groupPallets.reduce(
                    (a, b) => a + parseInt(b.cartonQuantity),
                    0
                  ) /
                    total) *
                    100 *
                    100
                ) /
                  100 +
                "%",
            };
            // console.log(groupPallets.reduce((a, b) => a + parseInt(b.palletQuantity), 0), groupPallets.map(b=>parseInt(b.palletQuantity)))
            for (let k = 0; k < groups.at(-1).length; k++) {
              let findGroup = this.getHeaderDetail(groups.at(-1)[k]);
              obj[findGroup.initialValue] = detail[findGroup.initialValue];
              if (!obj[findGroup.initialValue]) {
                obj[findGroup.initialValue] = "(none)";
              }
            }
            productResult.push(obj);
          }
        }
        result = [...result, ...productResult];
      }
      for (let i = 0; i < result.length; i++) {
        result[i].children = result.filter(
          (x) => x.parent == result[i].hash
        ).length;
      }
      result = result.filter((x) => x.level == maxLevel);
      result.sort((a, b) =>
        a.parent > b.parent ? 1 : b.parent > a.parent ? -1 : 0
      );
      let fields = groups
        .at(-1)
        .slice(0, groups.length - 1)
        .map((x) => this.getHeaderDetail(x).initialValue);
      // Sort data
      for (let i = 0; i < fields.length; i++) {
        let filteredFields = fields.filter((x, index) => index <= i);
        for (let j = 0; j < result.length; j++) {
          if (!result[j].parentHash) {
            result[j].parentHash = [];
          }
          if (!result[j].referenceHash) {
            result[j].referenceHash = [];
          }
          let values = filteredFields.map((x) => result[j][x]);
          let hash = values.join(":");
          result[j].parentHash.push(hash);
          result[j]["referenceHash" + "_" + i] = result[j].parentHash.join("_");
          result[j].referenceHash.push(result[j].parentHash.join("_"));
        }
      }
      let sortArray = result;
      let initialAlphabet = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let alphabet = [];
      for (let i = 0; i < 10; i++) {
        let lastLetter = i > 0 ? initialAlphabet[i - 1] : "";
        initialAlphabet.forEach((x) => {
          alphabet.push(lastLetter + x);
        });
      }
      for (let i = 0; i < groups.length - 1; i++) {
        let uniqueReferences = [
          ...new Set(result.map((x) => x.referenceHash[i])),
        ];
        for (let j = 0; j < uniqueReferences.length; j++) {
          let filter = result.filter(
            (x) => x.referenceHash[i] == uniqueReferences[j]
          );
          filter.sort((a, b) =>
            a[fields[i]] > b[fields[i]]
              ? 1
              : b[fields[i]] > a[fields[i]]
              ? -1
              : 0
          );
          for (let k = 0; k < filter.length; k++) {
            let findIndex = sortArray.findIndex(
              (x) => x.hash == filter[k].hash
            );
            if (!sortArray[findIndex]["Index"]) {
              sortArray[findIndex]["Index"] = [];
            }
            sortArray[findIndex]["Index"].push(alphabet[j]);
            sortArray[findIndex]["Index_" + i] = alphabet[j];
          }
        }
      }
      sortArray = sortArray.map((x) => ({ ...x, index: x.Index.join("") }));
      sortArray.sort((a, b) =>
        a.index > b.index ? 1 : b.index > a.index ? -1 : 0
      );
      result = sortArray;
      let uniqueParents = [...new Set(result.map((x) => x.parent))];
      let finalResult = [];
      for (let i = 0; i < uniqueParents.length; i++) {
        let filtered = result.filter((x) => x.parent == uniqueParents[i]);
        for (let j = 0; j < fields.length; j++) {
          let uniqueValues = [...new Set(filtered.map((x) => x[fields[j]]))];
          for (let k = 0; k < uniqueValues.length; k++) {
            let firstIndex = filtered.findIndex(
              (x) => x[fields[j]] == uniqueValues[k]
            );
            for (let l = 0; l < filtered.length; l++) {
              if (l == firstIndex) {
                filtered[l][fields[j]] = uniqueValues[k];
                filtered[l][fields[j] + "_rowspan"] = filtered.length;
              } else {
                filtered[l][fields[j]] = null;
              }
            }
          }
        }
        finalResult = [...finalResult, ...filtered];
      }
      for (let i = 0; i < fields.length; i++) {
        let indices = [...new Set(finalResult.map((x) => x["Index_" + i]))];
        for (let j = 0; j < indices.length; j++) {
          let values = [
            ...new Set(
              finalResult
                .filter((x) => x["Index_" + i] == indices[j])
                .map((x) => x[fields[i]])
                .filter(Boolean)
            ),
          ];
          for (let k = 0; k < values.length; k++) {
            let findFirstIndex = finalResult.findIndex(
              (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
            );
            let filter = finalResult.filter(
              (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
            );
            for (let l = 0; l < filter.length; l++) {
              let filterIndex = finalResult.findIndex(
                (x) => x.hash == filter[l].hash
              );
              if (filterIndex != findFirstIndex) {
                finalResult[filterIndex][fields[i]] = null;
                finalResult[filterIndex][fields[i] + "_rowspan"] = null;
              } else {
                finalResult[filterIndex][fields[i] + "_rowspan"] = filter
                  .map((x) => x[fields[i] + "_rowspan"])
                  .filter(Boolean)
                  .reduce((a, b) => a + b);
              }
            }
          }
        }
      }
      finalResult = this.formatObjects(finalResult);
      return finalResult;
    },
    // updateGrouping(headers) {
    //     this.groupBy = headers.filter((x) => x.group && x.show).map(x => x.value)
    //     let newArray = []

    //     this.productSummaryHeaders = headers
    //     this.productKey++
    // },
    sum(field) {
      return (
        (Math.round(
          this.groupData()
            .map((x) => x[field])
            .reduce((a, b) => a + b, 0)
        ) *
          100) /
        100
      );
    },
    updateSelectedHeaders() {
      this.productSummaryHeaders = this.allHeaders.filter(
        (x) => x.show || x.required
      );
      // console.log(this.productSummaryHeaders)
      this.updateHeaderSelection();
      this.productKey++;
    },
    updateHeaderSelection() {
      this.groupBy = this.productSummaryHeaders
        .filter((x) => x.group && x.show)
        .map((x) => x.value);
      // this.updateSelectedHeaders()
      this.productKey++;
    },
    viewItems(item, header) {
      console.log(item, header);
      console.log(this.stock);
    },
  },
};
</script>
<style scoped>
.ghost-card {
  opacity: 0.8;
  background: var(--v-modal-base) !important;
  border: 1px solid var(--v-primary-base);
  /* padding: 10px; */
}
.tableFixHead {
  width: 500px;
  table-layout: fixed;
  border-collapse: collapse;
}
.tableFixHead tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 50px;
}
</style>