var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":"","loading":_vm.loadingStock}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("view_week")])],1)]}}])},[_c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"color":"greyRaised"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.allHeaders.filter((x) => x.selectable)),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-action',{staticClass:"pr-0"},[_c('v-checkbox',{attrs:{"hide-details":""},on:{"change":function($event){return _vm.updateSelectedHeaders()}},model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)}),1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-0 pt-0"},[_c('table',{key:_vm.productKey,staticStyle:{"width":"100%","max-height":"30vh","overflow-y":"auto"}},[_c('thead',{staticStyle:{"position":"sticky"}},[_vm._l((_vm.productSummaryHeaders),function(header,index){return _c('th',{key:index,staticClass:"text-center",staticStyle:{"cursor":"grab"}},[(
                header.value == 'palletQuantity' ||
                header.value == 'cartonQuantity'
              )?_c('v-chip',[_vm._v(" "+_vm._s(_vm.sum(header.value))+" ")]):_vm._e()],1)}),_c('draggable',{style:({ cursor: _vm.columnDrag ? 'grabbing' : 'grab' }),attrs:{"tag":"tr","animation":200,"ghost-class":"ghost-card"},on:{"start":function($event){_vm.columnDrag = true},"end":function($event){_vm.columnDrag = false},"change":function($event){return _vm.updateHeaderSelection()}},model:{value:(_vm.productSummaryHeaders),callback:function ($$v) {_vm.productSummaryHeaders=$$v},expression:"productSummaryHeaders"}},_vm._l((_vm.productSummaryHeaders),function(header,index){return _c('th',{key:index,staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(header.text))]),(header.selectable)?_c('v-badge',{attrs:{"content":_vm.filters[header.value] ? _vm.filters[header.value].length : false,"value":_vm.filters[header.value] && _vm.filters[header.value].length > 0,"color":"primary","offset-y":"10"}},[_c('v-menu',{attrs:{"offset-y":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("filter_alt")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.headerOptions(header.value)),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-action',{staticClass:"pr-0"},[_c('v-checkbox',{attrs:{"value":item.value,"hide-details":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)}),1)],1)],1):_vm._e()],1)}),0),_c('tr')],2),_c('tbody',{staticStyle:{"height":"50vh","overflow-y":"auto"}},_vm._l((_vm.productBreakdown),function(item,i){return _c('tr',{key:i},[_vm._l((_vm.displayHeaders),function(header,index){return [(
                  header.value != 'cartonPrice' &&
                  header.value != 'kgPrice' &&
                  item[header.value] != null
                )?_c('td',{key:index,staticClass:"text-center",style:({ border: '0.4px solid rgb(95, 95, 95)' }),attrs:{"rowspan":item[header.value + '_rowspan']
                    ? item[header.value + '_rowspan']
                    : 1}},[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.viewItems(item, header.value)}}},[_vm._v(" "+_vm._s(item[header.value])+" ")])],1):(item[header.value] != null)?_c('td',{key:index,staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"amber"}},[_vm._v("lock")])],1):_vm._e()]})],2)}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }